*,
html,
body {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.theader {
  border: 1px solid black !important;
  font-weight: 400 !important;
  background: #aae0ff !important;
}

.trowHeader {
  border: 1px solid black !important;
  background: #aae0ff50 !important;
}
.theaderContent {
  height: 100% !important;
  width: 100% !important;
  font-weight: 400 !important;
  background: #aae0ff !important;
}

.trowHeaderContent {
  height: 100% !important;
  width: 100% !important;
  background: #aae0ff50 !important;
}

@media print {
  /* body * {
    visibility: hidden;
  }
  #paper-section,
  #paper-section * {
    visibility: visible;
  }
  #paper-section {
    position: absolute;
    left: 0;
    top: 0;
  } */
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .theader {
    border: 1px solid black;
    font-weight: 400;
    background: #aae0ff !important;
  }

  .trowHeader {
    border: 1px solid black;
    background: #aae0ff50 !important;
  }
}
