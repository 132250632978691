.viewBtn {
    --width: 50px;
    --height: 50px;
    --tooltip-height: 50px;
    --tooltip-width: 50px;
    --gap-between-tooltip-to-button: 18px;
    --tooltip-color: rgb(2,136,209);
    width: var(--width);
    height: var(--height);
    background: transparent;
    border: 1px solid rgb(2,136,209);
    position: relative;
    text-align: center;
    border-radius: 50%;
    font-family: "Arial";
    transition: background 0.3s;
  }
  
  .viewBtn::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: var(--tooltip-color);
    font-size: 0.9rem;
    color: #111;
    border-radius: .25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
  }
  
  .viewBtn::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: var(--tooltip-color);
    left: calc(50% - 10px);
    bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
  }
  
  .viewBtn::after,.viewBtn::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }
  
  .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .viewBtn-wrapper,.text,.icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: rgb(2,136,209);
  }
  
  .text {
    top: 0
  }
  
  .text,.icon {
    transition: top 0.5s;
  }
  
  .icon {
    color: #fff;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .icon svg {
    width: 24px;
    height: 24px;
  }
  
  .viewBtn:hover {
    background: rgb(2,136,209);
  }
  
  .viewBtn:hover .text {
    top: -100%;
  }
  
  .viewBtn:hover .icon {
    top: 0;
  }
  
  .viewBtn:hover:before,.viewBtn:hover:after {
    opacity: 1;
    visibility: visible;
  }
  
  .viewBtn:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
  }
  
  .viewBtn:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
  }
  