.dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: border 0.3s ease;
  }
  
  .dropzone.active {
    border-color: #0066cc;
    background-color: #f0f8ff;
  }
  
  .dropzone input {
    display: none;
  }

  .dropzone ul{
    width: 100%;
    display: flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 20px;
  }
  
  .dropzone ul li{
    height: 100px;
    width: 150px;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 10px;
  }
  
  .dropzone ul li .loadImage{
    height: 100px;
    width: 150px;
    border-radius: 10px;
  }

  .dropzone ul li .fileImage{
    height: 130px;
    width: 120px;
    border-radius: 10px;
  }