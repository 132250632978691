.stacked-image {
  display: flex;
  overflow: hidden;
  transition: 0.5s;
  position: relative;
  transition: 1s;
}

.item-image {
  flex-shrink: 0;
  width: 100%;
  object-fit: cover;
  height: 250px;
  transition: 0.5s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.Images-parent {
  flex-shrink: 0;
  width: 100%;
  object-fit: cover;
  height: 250px;
  transition: 0.5s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.sliderbtn ul {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: -100%;
  width: 100%;
  gap: 40px;
  list-style: none;
  z-index: 1;
}

.sliderbtn ul li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  cursor: pointer;
  /* border: 2px solid red; */
}

.sliderbtn ul li .slider-btn {
  position: absolute;
  height: 18px;
  width: 18px;
  border: 2px solid #fff;
  mix-blend-mode: difference;
  border-radius: 50%;
  right: 10px;
  top: -30px;
  z-index: 1;
}

.sliderbtn ul li .slider-btn::before {
  position: absolute;
  content: "";
  height: 10px;
  background-color: #fff;
  mix-blend-mode: difference;
  width: 10px;
  border-radius: 50%;
  top: -22%;
  left: -12%;
  margin-left: 4px;
  margin-top: 6px;
}

/* status Btn */
.status_btn {
  --s: 0.25em; /* control the wave*/

  padding: 0.4em 0.5em;
  background-color: #bf4d28;
  color: #fff;
  --_s: calc(var(--s) * 4) 51% repeat-x;
  --_r: calc(1.345 * var(--s)) at left 50%;
  --_g1: #000 99%, #0000 101%;
  --_g2: #0000 99%, #000 101%;
  --mask: radial-gradient(var(--_r) top calc(var(--s) * 1.9), var(--_g1))
      calc(50% - 2 * var(--s) - var(--_i, 0px)) 0 / var(--_s),
    radial-gradient(var(--_r) top calc(var(--s) * -0.9), var(--_g2))
      calc(50% - var(--_i, 0px)) var(--s) / var(--_s),
    radial-gradient(var(--_r) bottom calc(var(--s) * 1.9), var(--_g1))
      calc(50% - 2 * var(--s) + var(--_i, 0px)) 100% / var(--_s),
    radial-gradient(var(--_r) bottom calc(var(--s) * -0.9), var(--_g2))
      calc(50% + var(--_i, 0px)) calc(100% - var(--s)) / var(--_s);
  -webkit-mask: var(--mask);
  mask: var(--mask);
  clip-path: polygon(
    calc(2 * var(--s) - var(--_i, 0px)) 0,
    calc(100% - var(--_i, 0px)) 0,
    calc(100% - var(--s)) 50%,
    calc(100% - 2 * var(--s) + var(--_i, 0px)) 100%,
    calc(0% + var(--_i, 0px)) 100%,
    var(--s) 50%
  );
  cursor: pointer;
  transition: 0.35s;
}

button.alt {
  clip-path: polygon(
    calc(0% - var(--_i, 0px)) 0,
    calc(100% - 2 * var(--s) - var(--_i, 0px)) 0,
    calc(100% - var(--s)) 50%,
    calc(100% + var(--_i, 0px)) 100%,
    calc(2 * var(--s) + var(--_i, 0px)) 100%,
    var(--s) 50%
  );
}
.status_btn:hover {
  --_i: calc(2 * var(--s));
}
.status_btn.alt:hover {
  --_i: calc(-2 * var(--s));
}
.status_btn:active {
  background-image: linear-gradient(#0004 0 0);
}
.status_btn:focus-visible {
  -webkit-mask: none;
  clip-path: none;
  outline-offset: 0.1em;
  padding-block: 0.2em;
  margin-block: 0.2em;
  transition: 0s;
}

/* body {
    height: 100vh;
    margin: 0;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    place-content: center;
    background: #F6F7BD;
  } */
.status_btn {
  font-family: system-ui, sans-serif;
  font-weight: bold;
  font-size: 4rem;
  margin: 0;
  cursor: pointer;
  border: none;
}
